<template>
    <div class="accordion">
        <header>
            <span>{{title}}</span>

            <button @click="toggleAccordion()">
                <img
                    v-if="isOpen" 
                    src="@/assets/icons/chevron-up.svg" 
                    alt="chevron down"
                >
                <img
                    v-else 
                    src="@/assets/icons/chevron-down.svg" 
                    alt="chevron down"
                >
            </button>
        </header>
        
        <div v-show="isOpen">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        require: true
    },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding: 10px 0px;
        span {
            text-transform: capitalize;
            font-weight: 700;
            line-height: 24px;   
        }
        button {
            all: unset;
            cursor: pointer;
        }
    }
}
</style>
