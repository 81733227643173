
<template>
  <div class="home__description__content">
    <article slot="content">
        <p>
            RESILIENT is a generative NFT in 256 unique editions, built by artists Ira Greenberg and David G. Smith (aka BaconbitsCollective) in March 2022 as a response to the Russian invasion of Ukraine. 100% of primary sales on <a target="blank" href="https://emprops.io/">EmProps.io</a> and 100% of secondary sales on  <a href="https://stashh.io" target="blank">Stashh.io</a>  will be donated to <a target="blank" href="https://donate.thedigital.gov.ua/">Aid For Ukraine</a>.
        </p>
        <p>
            The artwork itself is an interactive 3D animation coded in three.js that renders an abstracted Ukraine flag as a mesh topographical surface with a custom physics engine. There is a lull at the beginning of the animation, before deterministically random "hedgehog" projectiles start to bombard the flag. This proceeds infinitely, never looping and never repeating. The viewer can zoom and rotate the 3D scene to observe the flag from all angles as it is attacked.
        </p>
        <p>
            Each of the 256 editions is wholly unique, with the speed, angle, location, and frequency of the projectiles determined by the private seed that is generated at the time of minting. The background color of the artwork is chosen by the collector via EmProps' proprietary Private Collector Input. This gives the collector the opportunity to affect the visual outcome of the piece they mint. It also allows collectors to acquire multiple editions and ensure they'll receive different color palettes.
        </p>
         <p>
            From the artists: "The flag stretches, shudders, deforms, but never tears. It is an undulating terrain absorbing repeated assault, releasing bursts of sunflower petals, floating gently into the dark abyss.
        </p>
        <p>
            "A flag is a symbol of the aspirations of a people, a nation, their hopes, dreams and resolve to maintain self-determination. The Ukrainian people are under brutal assault, existentially challenging these aspirations. Their resolve demonstrates to the world the very best of humanity, while being confronted by the very worst. Our very small contribution, as a collective, offers a model of resiliency, as a flag, a land mass, a symbol of hope, absorbing incursion, transformed into gentle blowing sunflower petals (Ukraine's National Flower).
        </p>
        <p>
            "The flag pulses, shifts and boils with the penetrating strikes, but it never tears, folds or falls. It remains resilient, as do the brave Ukrainian people."
        </p>
    </article>
  </div>
</template>

<script>
import Accordion from '@/components/UiUtils/Accordion.vue';
export default {
    components: { 
        Accordion 
    }, 
}
</script>

<style lang="scss" scoped>
.home__description__content {
  text-align: justify;
  @include respond-to("small and down") {
    padding-right: 0px;
  }
  p {
    margin: 0px 0px 16px 0px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ol {
    margin-left: 6px;
  }
  a{
    text-decoration: none;
    color: var(--color-emerald);
  }
}
</style>
