<template>
  <div :class="`alert ${backgroundColor}`">
	<span :class="`${fontColor}`"> {{ alertContent }}</span>
  </div>
</template>

<script>
export default {
  data() {
	return {};
  },
  props: {
	backgroundColor: {
	  type: String,
	  required: true,
	  validator: function (value) {
		return ["success", "warning", "danger"].indexOf(value) !== -1;
	  },
	},
	alertContent: {
	  type: String,
	  required: true,
	},
	fontColor: {
		type: String,
		required: false,
		default: 'white',
		validator: function (value) {
			return ["black", "white"].indexOf(value) !== -1;
		},
	}
  },
};
</script>

<style lang="scss" scoped>
.alert {
	padding: 7px 10px;
	width: 100%;
	column-gap: 10px;
	&.success {
		background-color: #589E5F;
	}
	&.warning {
		background-color: var(--color-yellow);
	}
	&.danger {
		background-color: var(--color-starter-red-02);
	}
	span {
		line-height: 24px;
		font-size: 16px;
		&.white {
			color: var(--color-neutral-white);
		}
		&.black {
			color: var(--color-neutral-black);
		}
	}
}
</style>
